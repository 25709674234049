import TagManager from 'react-gtm-module';

/**
 * Envía un evento personalizado al dataLayer de Google Tag Manager.
 *
 * @param {string} event - Nombre del evento.
 * @param {Object} data - Datos personalizados asociados al evento.
 */
const sendGTMEvent = (event, data) => {
  if (!event) {
    console.error('El nombre del evento es obligatorio.');
    return;
  }

  const eventData = {
    event, // Nombre del evento
    ...data, // Datos adicionales
  };

  TagManager.dataLayer({
    dataLayer: eventData,
  });

  console.log('Evento enviado al dataLayer:', eventData);
};

export default sendGTMEvent;
